import React, { Component } from 'react';
import ModalFedex from '../components/ModalFedex';
import TopMenu from '../components/TopMenu';
import '../assets/style/home.css';
import {
  updateUserSettings,
  sendUpdateWinner,
  getTitleSearchResults,
  getASINWinner,
  formatAsinData,
  isImportantClick,
  getInfoBoxesPrices,
  addNewShipment,
  closeShipment,
  fetchPreviousSources,
  updateCurrentShipment,
  ffCombinedResponse,
  overrideLP,
  overrideSource,
  overrideShipment,
  logBookScouter,
  updateActiveSourceApi,
  toggleMP,
  fetchScansHistory,
  fetchMPScansHistory,
  checkoutMPTrade,
  getUSFormattedDate,
  deleteScanLog,
  deleteMPScanLog,
} from '../includes/HelperHome';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Table,
  Image,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import ItemInfo from '../components/ItemInfo';
import InfoBoxes from '../components/InfoBoxes';
import AsinTitle from '../components/AsinTitle';
import ModalSettings from '../components/ModalSettings';
import { apiBase, winnersMap, socketIOServerAdd, documentTitle } from '../Config';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import io from 'socket.io-client';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ModalShipmentMap from '../components/ModalShipmentMap';
import auth from '../Auth';
import KeepaData from '../components/KeepaData';
import { CSSTransition } from 'react-transition-group';
import ModalSwitchProfile from '../components/ModalSwitchProfile';

const socket = io(socketIOServerAdd);

class Home extends Component {
  state = {
    textInAsinBox: '',
    asinData: [],
    fetchingAsinData: false,
    invalidAsin: false,
    triggers: [],
    triggersCat: [],
    moduleTriggerList: [],
    isValidBuy: -1,
    ValidBuySource: '',
    updatingWinner: false,
    moduleWinner: '',
    moduleWinnerColor: '',
    moduleWinnerText: '',
    subModuleWinner: '',
    isLiveLoad: 0,
    isPrintISBN: false,
    currentASIN: '',
    showSettingsModal: false,
    showAsinSelectModal: false,
    audioList: [],
    scansList: [],
    mpScansList: [],
    previousSources: [],
    searchText: '',
    ziffitCartId: '',
    animCompleted: false,
    infoBoxesData: [],
    audioPlaying: false,
    printersList: [],
    multipleItems: [],
    uploadingSettings: false,
    soundApiURL: apiBase.replace('/v1', ''),
    logData: {},
    isMPSourceInput: false,
    isFirstTimeSoundPlay: true,
    lastLogID: null,
    isIBDoubleClick: false,
    isAutoPrint: false,
    loadingTriggers: true,
    currentKeyBoard: 'AlphaNumeric',
    currentCat: 'All',
    currentProfile: '',
    b64: null,
    winnerMap: null,
    sourceName: '',
    sourcePalletValue: 0,
    sourceList: [],
    isUpdatingShipmentMap: false,
    currentListingProfile: 'NULL',
    shouldPlaySound: true,
    isRestricted: -1,
    user_data: auth.getUserData(),
    showShipmentMapModel: false,
    isPrinterServerConnected: false,
    shipmentsMap: [],
    listingProfiles: [],
    sounds: {
      //nightmare: new Audio("http://curtastic.com/nightmare.mp3")
    },
    showUpdateSource: false,
    mpMode: false,
    totalMPScanned: 0,
    totalMPCartCount: -1,
    showInfoAlert: false,
    infoAlertMessage: '',
    alertMessageType: 'primary',
    mpObject: {},
    newSource: '',
    showScanHistory: false,
    showMPScanHistory: false,
    showProfileSwitchModal: false,

    logoWL: '',
    wlFlags: {},
    isWLSite: false,

    // Fedex
    isFedexDone: false,
    inProcessSubmitFedex: false,
    fedexResponse: {},
    showFedexModal: false,
    selectedShipment: {},
  };

  constructor() {
    super();
    this.selectAsin = this.selectAsin.bind(this);
    this.initiateSearch = this.initiateSearch.bind(this);
    this.fetchAsinData = this.fetchAsinData.bind(this);
    this.handleAutoPrint = this.handleAutoPrint.bind(this);
    this.handleOpenSettingsModal = this.handleOpenSettingsModal.bind(this);
    this.submitSaveSettings = this.submitSaveSettings.bind(this);
    this.playSound = this.playSound.bind(this);
    this.printISBNLabel = this.printISBNLabel.bind(this);
    this.updateListingProfileName = this.updateListingProfileName.bind(this);
    this.handleOpenShipmentMap = this.handleOpenShipmentMap.bind(this);
    this.closeShipmentMap = this.closeShipmentMap.bind(this);
    this.saveShipmentMap = this.saveShipmentMap.bind(this);
    this.firstFetch = this.firstFetch.bind(this);
    this.updateShipmentCurrent = this.updateShipmentCurrent.bind(this);
    this.handleMMToggle = this.handleMMToggle.bind(this);
    this.logBSClick = this.logBSClick.bind(this);
    this.handleOpenPreviousScans = this.handleOpenPreviousScans.bind(this);
    this.handleOpenMPScans = this.handleOpenMPScans.bind(this);
    this.handleDeleteScanLog = this.handleDeleteScanLog.bind(this);
    this.checkoutMP = this.checkoutMP.bind(this);

    this.bookFreightMP = this.bookFreightMP.bind(this);

    this.getSiteIdentity = this.getSiteIdentity.bind(this);
    this.connectToPrintServer();
  }

  async getSiteIdentity(subDomain) {
    const response = await fetch(apiBase + `admin/whitelabel/site/${subDomain}`);
    const wlData = await response.json();
    console.log(`wlData`, wlData);
    if (wlData.status) {
      console.log(`Setting state!`, wlData.data);
      // console.log(wlData.data.winners_map.replace(/\s+/g, ' ').trim().replace(/\n|\r/g, ''));
      let winnersMapWL = winnersMap;
      try {
        winnersMapWL = JSON.parse(wlData.data.winners_map.trim().replace(/\n|\r/g, ''));
      } catch (err) {
        console.log(`Error parsing: `, wlData.data.winners_map.trim().replace(/\n|\r/g, ''));
      }

      this.setState(
        {
          logoWL: wlData.data.image_path_logo_2,
          wlFlags: wlData.data.site_flags ? JSON.parse(wlData.data.site_flags) : {},
          winnersMap: winnersMapWL,
        },
        () => {
          console.log(`State set`, this.state.logoWL);
        }
      );
      document.title = wlData.data.browser_title;
    }

    this.setState({ isWLReady: true });
  }

  // ** On launch **
  // Call First Fetch (load all lists), add event listener to DOM (auto focus ASIN),
  // Enable UI for multiple profiles
  componentDidMount() {
    if (this.state.user_data.token === undefined || this.state.user_data.user_id === undefined) {
      auth.logout(() => {
        this.props.history.push('/');
      });
    } else {
      this.asinInput !== undefined && this.asinInput.focus();

      // Call and see if home banner and logos are there for the team
      if (window.location.hostname.includes('scanporter.com') && window.location.hostname.split('.').length === 3) {
        console.log(`------------------\nScanporter code\n--------------\n\n`);
        const teamIdentifier = window.location.hostname.split('.')[0];
        // Check to see which team
        this.setState({ isWLSite: true });
        this.getSiteIdentity(teamIdentifier);
      }

      this.firstFetch();
      document.body.addEventListener('click', (event) => {
        const targetElement = event.target || event.srcElement;
        const isImportant = isImportantClick(targetElement);
        console.log(`Is this an important Click?`, isImportant);
        if (isImportant === true) {
          this.setState({ textInAsinBox: '' });
          this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
        } else return;
      });

      if (this.state.user_data.is_multi_profiles === 1 && this.state.user_data.profiles.length > 0) {
        if (this.state.user_data.profiles.length > 1) this.setState({ showProfileSwitchModal: true });
        else {
          this.setState({ currentProfile: this.state.user_data.profiles[0].name });
        }
      }
    }
  }

  /**  Actions related to toggles & Top Navigation */
  // Called when we receive `Incorrect Credentials` response from the search results
  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push('/');
    });
  };

  /**  Actions related to toggles & Top Navigation */
  // Handle when a normal scan is deleted
  handleDeleteScanLog(asin, scan_id) {
    confirmAlert({
      title: 'Delete Scan Log?',
      message: `This will permanently delete ${asin} (id: ${scan_id}). Are you sure to do this?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const resp = await deleteScanLog(scan_id, this.state.user_data.user_id, this.state.user_data.token);
            if (resp.status) this.setState({ scansList: resp.data });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  // Handle when a marketplace scan is deleted
  handleDeleteMPScanLog(asin, scan_id) {
    confirmAlert({
      title: 'Delete Scan Log?',
      message: `This will permanently delete ${asin} (Scan #${scan_id}). Are you sure to do this?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const resp = await deleteMPScanLog(scan_id, this.state.user_data.user_id, this.state.user_data.token);
            if (resp.status) this.setState({ mpScansList: resp.data, totalMPCartCount: resp.data.length });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  // Handle toggle Marketplace Switch
  async handleMMToggle(e) {
    if (e.target.checked === false) {
      this.setState({ showMPScanHistory: false });
    }

    // First clear current search
    this.setState({
      showAsinSelectModal: false,
      textInAsinBox: '',
      asinData: {},
      invalidAsin: false,
      searchText: '',
      infoBoxesData: [],
      isRestricted: -1,
    });

    // Then do the rest
    const mpSource = await toggleMP(this.state.user_data.user_id, this.state.user_data.token);
    if (undefined !== mpSource && 'current_status' in mpSource) {
      console.log(mpSource);
      let user_data = { ...this.state.user_data };
      user_data.is_mm = mpSource.current_status;

      if ('source_name' in mpSource) {
        user_data.mp_source.name = mpSource.source_name;
        user_data.mp_source.pallet_value = mpSource.pallet_value;
        user_data.mp_source.cart_count = mpSource.cart_count;
        this.setState({ totalMPScanned: mpSource.scanned_value });
      }
      this.setState({ user_data });
    }

    // Finally, highlight the asin input box
    this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
  }

  // Handle open/close previous scans history
  handleOpenPreviousScans() {
    this.setState({ showScanHistory: !this.state.showScanHistory }, () => {
      if (this.state.showScanHistory) {
        fetchScansHistory(this.state.user_data.user_id, this.state.user_data.token, (err, data) => {
          if (!err && data.status) this.setState({ scansList: data.data });
        });
      } else this.asinInput.focus();
    });
  }

  // Handle open/close previous marketplace scans history
  handleOpenMPScans() {
    this.setState({ showMPScanHistory: !this.state.showMPScanHistory, mpScansList: [] }, () => {
      if (this.state.showMPScanHistory) {
        fetchMPScansHistory(this.state.user_data.user_id, this.state.user_data.token, (err, data) => {
          if (!err && data.status) this.setState({ mpScansList: data.data });
        });
      } else this.asinInput.focus();
    });
  }
  ///////////////////////////////////////////////////////////

  /**
   * Socket commands receiving and handling actions
   * Commands received:
   * 1. isPrinterServerConnected - Display indicator if we're connected
   * 2. Add event: on handshake send userData details back to server to add
   *    list to socket.io users
   * 3. SIQ search - when someone hits scoutIQ search endpoint
   * 4. Broadcast - command received for "forced reload" on hitting "secret" endpoint
   * 5. Add event: On server disconnected
   *
   *  */
  connectToPrintServer() {
    socket.emit('client_details', {
      user_id: this.state.user_data.user_id,
      app_token: this.state.user_data.token,
      client_type: 'client',
    });

    socket.on('server_connected', () => {
      console.log(`Server Connected!`);
      this.setState({ isPrinterServerConnected: true });
    });

    socket.on('handshake', () => {
      console.log(`Handshake Received`);
      socket.emit('client_details', {
        user_id: this.state.user_data.user_id,
        app_token: this.state.user_data.token,
        client_type: 'client',
      });
    });

    socket.on('siq_search', (message) => {
      console.log('Search Request Received: ', message);
      if ('asin' in message) this.initiateSearch(message.asin);
    });

    socket.on('broadcast', (message) => {
      // Alert receive command 4
      if (message.command === 'reload') window.location.reload(true);
      else alert(message.command);
    });

    socket.on('server_disconnected', () => {
      console.log('PRINT SERVER DISCONNECTED');
      this.setState({ isPrinterServerConnected: false });
    });
  }

  // Loads list of shipments, sources, scans, audios & listing profiles in one single promise
  async firstFetch() {
    console.log(`here `);
    const ffData = await ffCombinedResponse(this.state.user_data);

    if ('mp_source' in this.state.user_data && 'scanned_value' in this.state.user_data.mp_source) {
      this.setState({
        totalMPScanned: parseFloat(this.state.user_data.mp_source.scanned_value),
        totalMPCartCount: Number(this.state.user_data.mp_source.cart_count),
      });
      // this.setState({});
    }

    fetchPreviousSources(this.state.user_data.user_id, this.state.user_data.token, (error, response) => {
      console.log(error, response);
      if (response !== null) this.setState({ previousSources: response.data });
    });

    for (let i = 0; i < ffData.length; i++) {
      if (ffData[i].api === 'shipments_list_all' && ffData[i].status === true)
        this.setState({ shipmentsMap: ffData[i].data });
      else if (ffData[i].api === 'sources_list' && ffData[i].status === true)
        this.setState({ sourceList: ffData[i].sources });
      else if (ffData[i].api === 'scans_list' && ffData[i].status === true)
        this.setState({ scansList: ffData[i].data });
      else if (ffData[i].api === 'audio_list' && ffData[i].status === true) {
        console.log(`Sound`);
        console.log(ffData[i]);
        let soundEffects = {};
        ffData[i].data.forEach((audioFile) => {
          soundEffects[audioFile.name] = new Audio(this.state.soundApiURL + audioFile.file_path);
          soundEffects[audioFile.name].addEventListener('ended', () => {
            console.log(`Audio Finished.`);
            this.setState({ audioPlaying: false });
          });
        });
        this.setState({
          audioList: ffData[i].data,
          sounds: soundEffects,
        });
      } else if (ffData[i].api === 'listing_profiles' && ffData[i].status === true)
        this.setState({ listingProfiles: ffData[i].data });
    }
  }

  /* Generate ISBN Label. Called when:
   * 1. Someone clicks on print icon
   * 2. Auto print is enabled and we receive a response from search API
   * */
  printISBNLabel = () => {
    const userSettings = JSON.parse(this.state.user_data.settings);

    const winnerName =
      this.state.winnerMap !== null
        ? this.state.winnerMap.display.name === '[SUB_MODULE]'
          ? this.state.subModuleWinner
          : this.state.winnerMap.display.name
        : '';

    socket.emit('print_label', {
      asin: this.state.asinData.meta.asin,
      searchText: this.state.searchText,
      title:
        this.state.asinData.meta && this.state.asinData.meta.title
          ? this.state.asinData.meta.title.replace(/[^\w\s]/gi, '')
          : '',
      logSource: 'logSource' in userSettings ? userSettings.logSource : '',
      user_id: this.state.user_data.user_id,
      app_token: this.state.user_data.token,
      winner: winnerName,
    });
  };

  /**
   * Functions to update info on `SCAN A BARCODE`
   * 1. Scan to Update Condition, Condition Notes, Self Wear, Buy Cost  | Barcode format: UC, UCN, USW, UBC
   * 2. Scan to Update Source
   * 3. Scan to Update Winner
   */
  async updateLP(command, lpKey) {
    if ('meta' in this.state.asinData) {
      const newCondition = command.split(':')[1];
      await overrideLP(
        this.state.user_data.user_id,
        this.state.user_data.token,
        this.state.asinData.meta.scan_insert_id,
        lpKey,
        newCondition.toLowerCase()
      );
    } else {
      alert(`You must scan an item first.`);
    }
  }

  async updateSource(newSource) {
    const response = await overrideSource(this.state.user_data.user_id, this.state.user_data.token, newSource);
    this.setState({ showUpdateSource: true, newSource: newSource, textInAsinBox: '' }, () => {
      window.setTimeout(() => {
        this.setState({ showUpdateSource: false });
      }, 3000);
    });
    const settingsString = response.data.data[0];
    let currentCookie = this.state.user_data;
    currentCookie.settings = settingsString.settings;
    auth.setUserData(currentCookie);
    this.setState({ user_data: currentCookie });
  }

  async updateWinner(newWinner) {
    console.log(`UPDATING WINNER`);
    this.updateStaticWinner(newWinner.toLowerCase() === 'reject' ? '' : newWinner);
  }
  //////////////////////////////////////////////////////

  /**
   * Initiates search operation. Takes asin as parameter
   * the second parameter amzRestrictedMessage is used when the function is called
   * recursively.
   * Algorithm Flow:
   * 1. Replace * with X & remove -
   * 2. If user is scanning Marketplace, and it's over. Alert and stop!
   * 3. Look for override codes in searchText and use updateXXX functions above
   * 4. If keyboard is numeric and the searchText length is 9 characters, append 'X' at the end
   * 5.
   */
  async initiateSearch(asin, amzRestrictedMessage) {
    let searchText = asin || this.state.textInAsinBox;
    searchText = searchText.replace('*', 'X');
    searchText = searchText.replaceAll('-', '');

    if (this.state.user_data.is_mm === 1 && this.state.totalMPScanned >= this.state.user_data.mp_source.pallet_value) {
      alert(`Pallet Full! Please switch to another pallet`);
      return;
    }

    if (searchText.substring(0, 2) === 'UC ') {
      await this.updateLP(searchText.split(' ')[1], 'condition');
      return;
    }
    if (searchText.substring(0, 3) === 'UCN ') {
      await this.updateLP(searchText.split(' ')[1], 'conditionNotes');
      return;
    }
    if (searchText.substring(0, 3) === 'USW ') {
      await this.updateLP(searchText.split(' ')[1], 'selfWear');
      return;
    }
    if (searchText.substring(0, 3) === 'UBC ') {
      await this.updateLP(searchText.split(' ')[1], 'buyCost');
      return;
    }
    if (searchText.substring(0, 8) === 'USOURCE ') {
      await this.updateSource(searchText.split(' ')[1]);
      return;
    }
    if (searchText.substring(0, 8) === 'UWROUTE ') {
      await this.updateWinner(searchText.split(' ')[1]);
      return;
    }
    searchText = searchText.toUpperCase();

    if (this.state.currentKeyBoard.toLowerCase() === 'numeric' && searchText.length === 9) {
      console.log(`Only Numeric. Appending X`);
      searchText += 'X';
    }

    this.setState({
      showAsinSelectModal: false,
      textInAsinBox: '',
      asinData: {},
      invalidAsin: false,
      searchText: searchText,
      infoBoxesData: [],
      isRestricted: undefined !== amzRestrictedMessage ? 1 : -1,
    });
    console.log(`${searchText} | ${searchText.length}`);

    if (
      searchText.length === 6 ||
      ((searchText.length === 10 || searchText.length === 12 || searchText.length === 13) &&
        (/^\d+$/.test(searchText) === true ||
          (/^\d+$/.test(searchText.substr(0, searchText.length - 1)) === true && searchText.slice(-1) === 'X') ||
          (/^\d+$/.test(searchText.substr(1, searchText.length)) === true && searchText.slice(0, 1) === 'B')))
    ) {
      console.log('Either a UPC or ISBN');
      this.fetchAsinData(searchText, amzRestrictedMessage);
    } else {
      console.log(`Doing a title search`);
      this.setState({ fetchingAsinData: true });
      const multipleItems = await getTitleSearchResults(
        searchText + ' ' + this.state.currentCat,
        this.state.user_data.user_id,
        this.state.user_data.token,
        this.state.currentCat
      );
      if ((multipleItems !== false) & (multipleItems.products !== undefined)) {
        if (multipleItems.products.length === 1) {
          console.log('Auto select: ' + multipleItems.products[0].asin);
          this.setState({
            fetchingAsinData: false,
            asinData: { meta: { image_url: '1231dsfsd' } },
          });
          this.selectAsin(multipleItems.products[0].asin, amzRestrictedMessage);
        } else {
          let isMatched = false;
          for (let i = 0; i < multipleItems.products.length; i++) {
            if (multipleItems.products[i].asin === this.state.searchText) {
              console.log('Auto select: ' + multipleItems.products[i].asin);
              this.setState({
                fetchingAsinData: false,
                asinData: { meta: { image_url: '1231dsfsd' } },
              });
              this.selectAsin(multipleItems.products[i].asin, amzRestrictedMessage);
              isMatched = true;
            }
          }
          if (isMatched === false) {
            this.setState({
              multipleItems: multipleItems.products,
              showAsinSelectModal: true,
              fetchingAsinData: false,
            });
          }
        }
      } else {
        this.setState({ fetchingAsinData: false, invalidAsin: true });
      }
    }
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  // Pick one of the results if multiple ASINs are returned.
  async fetchAsinData(asinToSearch, amzRestrictedMessage) {
    this.asinInput.focus();
    let soundKeys = Object.keys(this.state.sounds);

    const platformOS = this.getOS();

    /** iPhone SOUND Fix!! Works fine with other OS too */
    if (platformOS === 'iOS' && this.state.isFirstTimeSoundPlay === true) {
      this.setState({ isFirstTimeSoundPlay: false }, () => {
        for (let i = 0; i < soundKeys.length; i++) {
          const key = soundKeys[i];
          console.log(`WILL PLAY: ${this.state.sounds[key]}`);
          this.state.sounds[key]
            .play()
            .then((_) => {
              this.state.sounds[key].pause();
            })
            .catch((error) => {
              console.log(`Error playing sound.`, error);
            });
        }
      });
    }
    if (asinToSearch.length === 9) asinToSearch += 'X';
    console.log('ASIN TO SEARCH: ' + asinToSearch);

    this.setState({
      currentASIN: asinToSearch,
      fetchingAsinData: true,
      lastLogID: null,
      invalidAsin: false,
    });

    // lastScanInsertId will be undefined for all the searches except rejected ones
    console.log(`amzRestrictedMessage: ${amzRestrictedMessage}`);
    let asinJson = await getASINWinner(
      this.state.currentListingProfile,
      asinToSearch,
      this.state.isLiveLoad,
      this.state.user_data.token,
      amzRestrictedMessage,
      this.state.currentProfile
    );
    // asinJson = JSON.parse(JSON.stringify(asinJson));

    // Check if there's a need to open new shipment
    if (
      undefined !== asinJson &&
      'winner' in asinJson &&
      'winnerSubModule' in asinJson.winner &&
      asinJson.winner.winnerSubModule !== '' &&
      'meta' in asinJson &&
      asinJson.meta.scan_shipment_name === ''
    ) {
      const mapped = winnersMap.find((winner) => {
        return (
          (winner.conditions.module === asinJson.winner.winnerModule &&
            winner.conditions.subModule === asinJson.winner.winnerSubModule) ||
          winner.conditions.module === asinJson.winner.winnerSubModule
        );
      });

      // var displayName =
      //   undefined !== mapped
      //     ? mapped.display.name !== "[SUB_MODULE]"
      //       ? mapped.display.name
      //       : mapped.conditions.module
      //     : "BOOKSCOUTER";

      if (mapped !== undefined) {
        var displayName = mapped.display.name !== '[SUB_MODULE]' ? mapped.display.name : mapped.conditions.module;
        this.setState({
          alertMessageType: 'primary',
          showInfoAlert: true,
          infoAlertMessage: `Creating New Shipment for ${displayName} ...`,
        });

        const response = await this.saveShipmentMap(displayName, true);
        let newShipmentName = '';
        for (let i = 0; i < response.length; i++) {
          if (response[i].display_name === displayName && response[i].is_current === 1) {
            newShipmentName = response[i].name;
            this.setState({
              alertMessageType: 'success',
              showInfoAlert: true,
              infoAlertMessage: `Created new shipment for ${displayName} - ${response[i].name}`,
            });
            break;
          }
        }
        if (newShipmentName !== '') {
          window.setTimeout(() => {
            this.setState({ showInfoAlert: false });
          }, 3000);
          overrideShipment(
            this.state.user_data.id,
            this.state.user_data.token,
            asinJson.meta.scan_insert_id,
            newShipmentName
          );
        }
      }
    }

    // Check for amazon restriction (if enabled) AFTER we get asin out of search text.
    const userSettings =
      this.state.user_data.settings !== '' && this.state.user_data.settings !== null
        ? JSON.parse(this.state.user_data.settings)
        : {};

    if (
      ('is_mm' in this.state.user_data &&
        this.state.user_data.is_mm === 1 &&
        'meta' in asinJson &&
        'is_commonly_restricted' in asinJson.meta &&
        asinJson.meta.is_commonly_restricted === true) ||
      ('isCheckRestricted' in userSettings &&
        userSettings.isCheckRestricted === 'yes' &&
        'restrictedSource' in userSettings &&
        userSettings.restrictedSource === 'database' &&
        'meta' in asinJson &&
        'is_commonly_restricted' in asinJson.meta &&
        asinJson.meta.is_commonly_restricted === true)
    )
      this.setState({ isRestricted: 1 });

    // console.log(`DB CHECK >>>>>>>`);
    // if (

    // ) {
    //   this.setState({ isRestricted: 1 });
    // }
    // console.log(`Chrome extension checking`);

    if (
      'isCheckRestricted' in userSettings &&
      userSettings.isCheckRestricted === 'yes' &&
      typeof isRestricted === 'function' &&
      (undefined === amzRestrictedMessage || amzRestrictedMessage === '') &&
      'meta' in asinJson &&
      'asin' in asinJson.meta &&
      (('restrictedSource' in userSettings && userSettings.restrictedSource !== 'database') ||
        !('restrictedSource' in userSettings))
    ) {
      console.log(`Checking for Amazon Restrictions for ${asinJson.meta.asin}`);
      // safe to use the function
      if (asinJson.meta.asin !== '') {
        window.isRestricted(asinJson.meta.asin, this.state.user_data.token, (res) => {
          if (res !== false) {
            try {
              const amzResponse = JSON.parse(res);
              if (amzResponse.status === true) {
                console.log(amzResponse.data);

                const errorMessageIndex = amzResponse.data.qualificationMessages.findIndex(
                  (qm) =>
                    (qm.qualificationMessage.includes('You are not approved to list this product') ||
                      qm.qualificationMessage.includes('You need approval') ||
                      qm.qualificationMessage.includes('brand') ||
                      qm.qualificationMessage.includes('you cannot list') ||
                      qm.qualificationMessage.includes('This product has other listing limitations')) &&
                    (qm.conditionList === null || qm.conditionList.toLowerCase().includes('used'))
                );

                var isRestricted = errorMessageIndex !== -1 ? 1 : 0;
                this.setState({ isRestricted }, () => {
                  if (isRestricted === 1) {
                    // Step 1. Play Audio for Amazon Rejected
                    this.playSound('audioAmzRejected');

                    // Step 2. Loop infinite until we get the lastScanId
                    // let lastScanInsertId = undefined;
                    // while (true) {
                    //   console.log(`Stuck into loop`);
                    //   if ("meta" in this.state.asinData && "scan_insert_id" in this.state.asinData.meta) {
                    //     lastScanInsertId = this.state.asinData.meta.scan_insert_id;
                    //     break;
                    //   }
                    // }

                    // Step 2. Check user Settings for "onRestrictedSkipAmazon" & Hit back API with is_rejected parameter set to 1.
                    if ('onRestrictedSkipAmazon' in userSettings && userSettings.onRestrictedSkipAmazon === 'yes') {
                      console.log(
                        `Amazon Qualification Messages`,
                        amzResponse.data.qualificationMessages[errorMessageIndex]
                      );
                      this.initiateSearch(
                        asinJson.meta.asin,
                        amzResponse.data.qualificationMessages[errorMessageIndex].qualificationMessage
                      );
                    }
                  }
                });
              }
            } catch (err) {}
          }
        });
      }
    }

    if (asinJson !== '' && asinJson !== false && 'status' in asinJson) {
      if (asinJson.status === 'success') {
        // Extract InfoBoxes data from API response.
        const infoBoxesData = getInfoBoxesPrices(asinJson, this.state.user_data.is_mm);

        // If MP Scanned is there
        if ('mp_scan_value' in asinJson.meta) {
          let user_data = { ...this.state.user_data };

          user_data.mp_source.name = asinJson.meta.mp_name;
          user_data.mp_source.pallet_value = asinJson.meta.mp_pallet_value;
          user_data.mp_source.cart_count = asinJson.meta.mp_cart_count;

          this.setState({
            user_data: user_data,
            totalMPScanned: asinJson.meta.mp_scan_value,
            totalMPCartCount: asinJson.meta.mp_cart_count,
          });
        }
        // Read winnersMap to know what to show and play for this round
        const winnerMap = winnersMap.find((w) => {
          return w.conditions.module === asinJson.winner.winnerModule &&
            (w.conditions.subModule === '' || w.conditions.subModule === asinJson.winner.winnerSubModule) &&
            (w.conditions.forTeams.length === 0 || w.conditions.forTeams.indexOf(this.state.user_data.team_id) >= 0) &&
            (w.conditions.excludeTeams.length === 0 ||
              w.conditions.excludeTeams.indexOf(this.state.user_data.team_id) < 0)
            ? true
            : false;
        });

        this.setState({
          asinData: formatAsinData(asinJson),
          infoBoxesData: infoBoxesData,
          fetchingAsinData: false,
          isValidBuy: 1,
          winnerMap: winnerMap !== undefined ? winnerMap : null,
          moduleWinner: asinJson.winner.winnerModule,
          moduleWinnerColor: 'winnerModuleColor' in asinJson.winner ? asinJson.winner.winnerModuleColor : '',
          moduleWinnerText: 'winnerModuleText' in asinJson.winner ? asinJson.winner.winnerModuleText : '',
          subModuleWinner: asinJson.winner.winnerSubModule,
          lastLogID: 'meta' in asinJson && 'scan_insert_id' in asinJson.meta ? asinJson.meta.scan_insert_id : null,
        });

        console.log(asinJson.winner.winnerModule);

        // Auto Print if it's an ACCEPT & isAutoPrint is true
        asinJson.winner.winnerModule !== '' && this.state.isAutoPrint === true && this.printISBNLabel(asinToSearch);

        let audioString = winnerMap !== undefined ? winnerMap.display.sound : '';
        this.playSound(audioString);
      } else {
        if ('status' in asinJson && asinJson.status === false && asinJson.error === 'Invalid Credentials')
          this.handleLogout();
        else {
          console.log(asinJson);
          this.setState({
            invalidAsin: true,
            isValidBuy: 0,
            fetchingAsinData: false,
          });
          this.playSound('audioNoResult');
        }
      }
    } else {
      console.log(asinJson);
      if (asinJson.error === 'Invalid Credentials') this.handleLogout();
      else {
        this.setState({
          invalidAsin: true,
          isValidBuy: 0,
          fetchingAsinData: false,
        });
        this.playSound('audioNoResult');
      }
    }
    this.setState({ textInAsinBox: '' });
    if (this.asinInput !== null) this.asinInput.focus();
  }

  playSound(winner) {
    //if (winner !== "audioAmzRejected" && this.state.isRestricted === 1) return false;
    if (this.state.shouldPlaySound === true) {
      if (this.state.isRestricted === 1 && (winner.includes('FBA') || winner.includes('MF'))) {
        console.log(`Playing sound || Amazon restricted but not wholesale, so playing amazon restricted sound`);
        winner = 'audioAmzRejected';
      }
      console.log(`Playing sound for: ${winner}`, this.state.isRestricted);
      // Check to see if a valid sound setting is there
      try {
        const userSettings = JSON.parse(this.state.user_data.settings);
        console.log(userSettings);

        if (winner in userSettings && userSettings[winner] !== '') {
          console.log('Winner: ', winner);
          const aindex = this.state.audioList.findIndex((audio) => audio.name === userSettings[winner]);
          console.log(`aindex`, aindex);
          const soundToPlay = this.state.audioList[aindex].name;
          console.log(soundToPlay);

          if (this.state.audioPlaying === true) {
            console.log(`Another audio already playing. Waiting for stop!`);
            const currentSound = this.state.sounds[soundToPlay];
            currentSound.pause();
            currentSound.currentTime = 0;
          }

          this.setState({ audioPlaying: true }, () => {
            console.log(`Playing Audio ...`);
            this.state.sounds[soundToPlay].play();
          });
        } else {
          console.log(`Sound`, `Winner not found`);
        }
      } catch (err) {
        console.log('No valid sound settings: ', err);
      }
    } else console.log(`Sound disabled.`);
  }

  handleBSDoubleClick = (BSUrl) => {
    console.log('Last Click Deactivated');
    this.setState({ isIBDoubleClick: true }, () => {
      window.open(BSUrl, '_blank');
    });
  };

  updateStaticWinner = async (newWinner) => {
    this.setState({ isIBDoubleClick: false });

    /**
     * We use timer to wait and be sure if it's a single click and not double. 'cause when someone issues
     * a double click, a single click is fired along with it too. So, we wait a few millisecs before working on the`
     * single click event action. In case a double click is issues, we set isIBDoubleClick in state to TRUE.
     */
    this.staticTimer = this.updateStaticWinnerTimer = setTimeout(
      async () => {
        if (this.state.isIBDoubleClick === false || newWinner !== 'BS') {
          console.log(`Switching winner to: ${newWinner} | Current moduleWinner: ${this.state.moduleWinner}`);

          let winnerSubModule = newWinner;
          let winnerModule =
            newWinner === ''
              ? ''
              : newWinner === 'FBA' || newWinner === 'MF'
              ? 'AMAZON'
              : newWinner === 'MP'
              ? 'MARKETPLACE'
              : newWinner === 'RMS' ||
                newWinner === 'SBYB' ||
                newWinner === 'BR' ||
                newWinner === 'BRB' ||
                newWinner === 'UBB' ||
                newWinner === 'ZIFFIT'
              ? 'WHOLESALE'
              : 'BOOKSCOUTER';

          if (winnerModule === 'MARKETPLACE') winnerSubModule = this.state.user_data.mp_source.name;

          let isValidBuy = 1;
          if (
            (this.state.moduleWinner === 'MARKETPLACE' || winnerSubModule === this.state.subModuleWinner) &&
            winnerModule === this.state.moduleWinner &&
            this.state.isValidBuy === 1
          ) {
            winnerSubModule = '';
            winnerModule = '';
            isValidBuy = 0;
          }

          // if (newWinner === "ZIFFIT") {
          //   console.log(`HOLA`);
          //   const ziffitVendor = this.state.asinData.prices.vendors.find((vendor) => vendor.vendor === "ZIFFIT");
          //   if (ziffitVendor !== undefined) {
          //     console.log(`ZIFFIT MODIFY CART`, ziffitVendor.ziffitCartId, this.state.asinData.meta.asin, isValidBuy);
          //     updateZiffitCart(ziffitVendor.ziffitCartId, this.state.asinData.meta.asin, isValidBuy);
          //   }
          // }
          const winnerMap = winnersMap.find((w) => {
            return w.conditions.module === winnerModule &&
              (w.conditions.subModule === '' || w.conditions.subModule === winnerSubModule) &&
              (w.conditions.forTeams.length === 0 ||
                w.conditions.forTeams.indexOf(this.state.user_data.team_id) >= 0) &&
              (w.conditions.excludeTeams.length === 0 ||
                w.conditions.excludeTeams.indexOf(this.state.user_data.team_id) < 0)
              ? true
              : false;
          });

          this.setState({ updatingWinner: true });

          const response = await sendUpdateWinner(
            this.state.user_data,
            this.state.lastLogID,
            this.state.asinData.meta.asin,
            this.state.asinData.meta.product_group,
            this.state.asinData.meta.searchText,
            this.state.asinData.meta.idType,
            winnerModule,
            winnerSubModule
          );

          this.setState({ updatingWinner: false });

          if (response.status === true) {
            this.setState({
              isValidBuy: isValidBuy,
              winnerMap: winnerMap !== undefined ? winnerMap : null,
              moduleWinner: isValidBuy === 1 ? winnerModule : '',
              subModuleWinner: isValidBuy === 1 ? winnerSubModule : '',
              moduleWinnerColor: response.data.color,
              moduleWinnerText: response.data.text,
            });
          }
        }
        clearTimeout(this.staticTimer);
      },

      200
    );
    this.setState({ textInAsinBox: '' });
    this.asinInput !== undefined && this.asinInput.focus();
  };

  handleOpenSettingsModal() {
    console.log('Opening Settings Modal');
    this.setState({ showSettingsModal: true });
  }
  handleAutoPrint(evt) {
    this.setState({ isAutoPrint: evt.target.checked });
  }

  async submitSaveSettings(userSettingsJson) {
    this.setState({ uploadingSettings: true });

    const response = await updateUserSettings(
      this.state.user_data.user_id,
      this.state.user_data.token,
      userSettingsJson
    );

    if (response !== false && response.status === 1) {
      try {
        const settingsString = response.data.data[0];
        let currentCookie = this.state.user_data;
        currentCookie.settings = settingsString.settings;
        auth.setUserData(currentCookie);
        this.setState({ user_data: currentCookie });
      } catch (err) {
        console.log('Invalid user settings. Probably empty string');
      }
    }

    this.setState({
      showSettingsModal: false,
      uploadingSettings: false,
    });
  }

  selectAsin = (asin, amzRestrictedMessage) => {
    console.log(`Selecting asin: ${asin}`);
    this.setState({ textInAsinBox: '', showAsinSelectModal: false }, () =>
      this.fetchAsinData(asin, amzRestrictedMessage)
    );
  };

  updateListingProfileName = (profile_name) => {
    this.setState({ currentListingProfile: profile_name });
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  checkoutMP() {
    confirmAlert({
      title: 'Confirm checkout?',
      message: `Your shipment contains ${this.state.mpScansList.length} items with a total value of $${parseFloat(
        this.state.mpScansList.reduce((sum, scan) => sum + scan.mp_estimated_profit, 0)
      ).toFixed(2)}`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            this.setState({ isCheckingOutMPTrade: true });
            checkoutMPTrade(this.state.user_data.user_id, this.state.user_data.token, () => {
              console.log('marketplace ID generated');
              this.setState({ isCheckingOutMPTrade: false }, () => {
                if (documentTitle !== 'EMPTY SHELVES') {
                  let user_data = { ...this.state.user_data };
                  user_data.is_mm = null;
                  this.setState({ user_data });
                }
                this.setState({ showMPScanHistory: false, totalMPCartCount: -1 });
                this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
              });
            });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  handleOpenShipmentMap() {
    this.setState({ showShipmentMapModel: true });
  }

  async saveShipmentMap(displayName, isAuto) {
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    const isAutoOpen = undefined !== isAuto && isAuto === true ? true : false;

    let moduleNames = [];
    winnersMap.forEach((wm) => {
      const mapArr = { mod: wm.conditions.module, subMod: wm.conditions.subModule };
      if (wm.display.name === displayName && moduleNames.indexOf(mapArr) < 0) moduleNames.push(mapArr);
    });

    this.setState({ isUpdatingShipmentMap: true });
    const response = await addNewShipment(JSON.stringify(moduleNames), displayName, user_id, token, isAutoOpen);
    this.setState({ isUpdatingShipmentMap: false });
    if (response.status === true) {
      this.setState({ shipmentsMap: response.data });
      return response.data;
    }
  }

  async closeShipmentMap(shipmentId) {
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    this.setState({ isUpdatingShipmentMap: true });
    const response = await closeShipment(shipmentId, user_id, token);
    if (response.status === true) this.setState({ shipmentsMap: response.data });
    this.setState({ isUpdatingShipmentMap: false });
  }

  async updateShipmentCurrent(shipmentName, displayName) {
    console.log(shipmentName + ' | ' + displayName);
    var user_id = this.state.user_data.user_id;
    var token = this.state.user_data.token;

    this.setState({ isUpdatingShipmentMap: true });
    const response = await updateCurrentShipment(shipmentName, displayName, user_id, token);
    if (response.status === true) this.setState({ shipmentsMap: response.data });
    this.setState({ isUpdatingShipmentMap: false });
  }

  // async saveNewMPSource() {
  //   const response = await addNewMPSource(
  //     this.state.sourceName,
  //     this.state.sourcePalletValue,
  //     this.state.user_data.user_id,
  //     this.state.user_data.token
  //   );
  // }

  async logBSClick(bsPrice) {
    const response = await logBookScouter(
      this.state.asinData.meta.scan_insert_id,
      this.state.asinData.meta.asin,
      bsPrice,
      this.state.user_data.user_id,
      this.state.user_data.token
    );
    console.log(response);
  }

  async updateActiveSource(newSource, actionFor) {
    console.log(`Updating Current Pallet Source to: ${newSource}`);
    const mpSource = await updateActiveSourceApi(
      this.state.user_data.user_id,
      this.state.user_data.token,
      newSource,
      actionFor
    );

    if (undefined !== mpSource && 'source_name' in mpSource) {
      console.log(mpSource);
      let user_data = { ...this.state.user_data };
      // user_data.is_mm = mpSource.current_status;
      user_data.mp_source.name = mpSource.source_name;
      user_data.mp_source.cart_count = mpSource.cart_count;
      user_data.mp_source.pallet_value = mpSource.pallet_value;
      // this.setState({ totalMPScanned: mpSource.scanned_value });
      this.setState({ user_data, totalMPScanned: mpSource.scanned_value });
    }

    this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
  }

  async bookFreightMP(shipmentData) {
    this.setState({ isCheckingOutMPTrade: true });
    checkoutMPTrade(this.state.user_data.user_id, this.state.user_data.token, shipmentData, () => {
      console.log('marketplace ID generated');
      this.setState({ isCheckingOutMPTrade: false }, () => {
        if (documentTitle !== 'EMPTY SHELVES') {
          let user_data = { ...this.state.user_data };
          user_data.is_mm = null;
          this.setState({ user_data });
        }
        this.setState({ showMPScanHistory: false, showFedexModal: false, totalMPCartCount: -1 });
        this.asinInput !== undefined && this.asinInput !== null && this.asinInput.focus();
      });
    });
  }

  render() {
    let domainName = window.location.hostname;

    const progressBarPercentage = (
      (this.state.totalMPScanned * 100) /
      this.state.user_data.mp_source.pallet_value
    ).toFixed(0);

    // if (progressBarPercentage >= 100) {
    //   alert("Pallet Full! Please switch to another pallet.");
    // }
    const componentToReturn =
      this.state.user_data.token !== undefined ? (
        <div>
          <ModalSwitchProfile
            changeActiveProfile={(name) => this.setState({ currentProfile: name, showProfileSwitchModal: false })}
            profiles={this.state.user_data.profiles}
            showProfileSwitchModal={this.state.showProfileSwitchModal}
            handleClose={() => this.setState({ showProfileSwitchModal: false })}
          />
          <ModalFedex
            isFedexDone={this.state.isFedexDone}
            inProcessSubmitFedex={this.state.isCheckingOutMPTrade}
            fedexResponse={this.state.fedexResponse}
            bookFreightMP={this.bookFreightMP}
            selectedShipment={this.state.selectedShipment}
            showModal={this.state.showFedexModal}
            handleClose={() => this.setState({ showFedexModal: false })}
          />
          <ModalSettings
            updateCurrentSource={(newSource) => {
              let user_data = { ...this.state.user_data };
              if (user_data.settings !== '' && user_data.settings !== undefined && user_data.settings !== null) {
                let userSettings = JSON.parse(user_data.settings);
                userSettings.logSource = newSource;
                user_data.settings = JSON.stringify(userSettings);
                this.setState({ user_data });
              }
            }}
            previousSources={this.state.previousSources}
            user_data={this.state.user_data}
            printersList={this.state.printersList}
            isMM={'is_mm' in this.state.user_data ? this.state.user_data.is_mm : null}
            userSettings={
              this.state.user_data.settings !== '' &&
              this.state.user_data.settings !== undefined &&
              this.state.user_data.settings !== null
                ? JSON.parse(this.state.user_data.settings)
                : {}
            }
            audioList={this.state.audioList}
            uploadingSettings={this.state.uploadingSettings}
            submitSaveSettings={this.submitSaveSettings}
            showSettingsModal={this.state.showSettingsModal}
            handleClose={() => this.setState({ showSettingsModal: false })}
          />

          <ModalShipmentMap
            isUpdatingShipmentMap={this.state.isUpdatingShipmentMap}
            shipmentsMap={this.state.shipmentsMap}
            updateShipmentCurrent={this.updateShipmentCurrent}
            saveShipmentMap={this.saveShipmentMap}
            updateCloseShipment={this.closeShipmentMap}
            user_data={this.state.user_data}
            showShipmentMapModel={this.state.showShipmentMapModel}
            handleClose={() => this.setState({ showShipmentMapModel: false })}
          />

          <TopMenu
            wlFlags={this.state.wlFlags}
            isWLSite={this.state.isWLSite}
            logoWL={this.state.logoWL}
            handleOpenMPScans={this.handleOpenMPScans}
            handleOpenPreviousScans={this.handleOpenPreviousScans}
            history={this.props.history}
            handleMMToggle={this.handleMMToggle}
            handleOpenShipmentMap={this.handleOpenShipmentMap}
            handleAutoPrint={this.handleAutoPrint}
            shouldPlaySound={this.state.shouldPlaySound}
            handleSoundToggle={(e) => this.setState({ shouldPlaySound: e.target.checked })}
            isAutoPrint={this.state.isAutoPrint}
            isPrinterServerConnected={this.state.isPrinterServerConnected}
            // QZPrinter={this.state.QZPrinter}
            handleOpenSettingsModal={this.handleOpenSettingsModal}
            handleOpenProfileSwitchModal={() => {
              this.setState({ showProfileSwitchModal: true });
            }}
            handleLiveToggle={(e) => {
              this.setState({ isLiveLoad: e.target.checked === true ? 1 : 0 });
            }}
            handlePrintToggle={(e) => {
              this.setState({ isPrintISBN: e.target.checked });
            }}
            listingProfiles={this.state.listingProfiles}
            isMM={'is_mm' in this.state.user_data ? this.state.user_data.is_mm : null}
            isMultiProfiles={
              this.state.user_data.is_multi_profiles === 1 && this.state.user_data.profiles.length > 0 ? true : false
            }
            userSettings={
              this.state.user_data.settings !== '' &&
              this.state.user_data.settings !== undefined &&
              this.state.user_data.settings !== null
                ? JSON.parse(this.state.user_data.settings)
                : {}
            }
            cartCount={this.state.totalMPCartCount}
          />
          {/* 
          <div key={"hello01"} id="scan-history-2">
            <div key={"hello1"} style={{ margin: "20px", height: "100px", background: "blue" }}>
              <h3>RECENT SCANS</h3>
            </div>
          </div> */}

          <CSSTransition
            in={this.state.showScanHistory}
            appear={true}
            timeout={600}
            onEntering={() => {
              this.setState({ animCompleted: false });
            }}
            onEntered={() => {
              this.setState({ animCompleted: true });
            }}
            onExiting={() => {
              this.setState({ animCompleted: false });
            }}
            classNames="fade"
            unmountOnExit={true}
          >
            <div key={'hello01'} id="scan-history">
              <div key={'hello1'}>
                {this.state.animCompleted && (
                  <div className="scans-list">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                      <div>
                        <h3 style={{ marginBottom: 0 }}>RECENT SCANS</h3>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        <Button variant="dark" size="sm" onClick={() => this.setState({ showScanHistory: false })}>
                          CLOSE
                        </Button>
                      </div>
                    </div>

                    <table className="scans-table">
                      <tbody>
                        <tr>
                          <th>ASIN</th>
                          <th>Title</th>
                          <th>Scanned ON</th>
                          <th>Routed to</th>
                          <th>Profit</th>
                          <th>Actions</th>
                        </tr>
                        {this.state.scansList.map((scan) => {
                          return (
                            <tr>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {scan.asin}
                                </span>
                              </td>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {scan.title}
                                </span>
                              </td>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {getUSFormattedDate(scan.scanned_on)}
                                </span>
                              </td>
                              <td>
                                {scan.module_winner === '' ? (
                                  <span style={{ color: '#DC3545', fontWeight: 'bold' }}>REJECTED</span>
                                ) : (
                                  scan.module_winner + ' - ' + scan.hl_winner
                                )}
                              </td>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {scan.module_winner === ''
                                    ? '-'
                                    : scan.hl_winner === 'ZIFFIT'
                                    ? scan.ziffit_price
                                    : '$' + scan.highest_profit}
                                </span>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => this.handleDeleteScanLog(scan.asin, scan.id)}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            in={this.state.showMPScanHistory}
            appear={true}
            timeout={600}
            onEntering={() => {
              this.setState({ animCompleted: false });
            }}
            onEntered={() => {
              this.setState({ animCompleted: true });
            }}
            onExiting={() => {
              this.setState({ animCompleted: false });
            }}
            classNames="fade"
            unmountOnExit={true}
          >
            <div key={'hello01'} id="mp-scan-history">
              <div key={'hello1'}>
                {this.state.animCompleted && (
                  <div className="scans-list">
                    <Row
                      style={{
                        alignItems: 'center',
                        marginBottom: '20px',
                        paddingBottom: '10px',
                        borderBottom: '1px solid #303163',
                      }}
                    >
                      <Col md={6}>
                        <h3>{documentTitle === 'EMPTY SHELVES' ? 'Empty Shelves Cart' : 'Marketplace Cart'}</h3>
                      </Col>
                      <Col md={6}>
                        <div style={{ textAlign: 'right' }}>
                          <span style={{ color: 'black', marginRight: 20, fontWeight: 'bold' }}>
                            $
                            {parseFloat(
                              this.state.mpScansList.reduce((sum, scan) => sum + scan.mp_estimated_profit, 0)
                            ).toFixed(2)}
                          </span>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                              this.setState({ showFedexModal: true });
                              //this.checkoutMP();
                            }}
                          >
                            {this.state.isCheckingOutMPTrade === true ? (
                              <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <span>Checkout</span>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <table className="scans-table" style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th>ASIN</th>
                          <th>Title</th>
                          <th>Date Scanned</th>
                          <th>Price</th>
                          <th>Actions</th>
                        </tr>
                        {this.state.mpScansList.map((scan) => {
                          return (
                            <tr>
                              {/* <td>
                                <span style={{ color: "black", fontWeight: "bold" }}>{scan.id}</span>
                              </td> */}
                              <td>
                                <span
                                  style={
                                    scan.mp_estimated_profit === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}
                                  }
                                >
                                  {scan.asin}
                                </span>
                              </td>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {scan.title}
                                </span>
                              </td>
                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {getUSFormattedDate(scan.scanned_on)}
                                </span>
                              </td>

                              <td>
                                <span style={scan.module_winner === '' ? { color: '#DC3545', fontWeight: 'bold' } : {}}>
                                  {scan.module_winner === '' ? '-' : '$' + scan.mp_estimated_profit}
                                </span>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => this.handleDeleteMPScanLog(scan.asin, scan.id)}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>

          {this.state.showUpdateSource && (
            <Alert variant="success" dismissible onClose={() => this.setState({ showUpdateSource: false })}>
              Source updated successfully to <b>{this.state.newSource}</b>!
            </Alert>
          )}

          {this.state.showInfoAlert && (
            <Alert
              variant={this.state.alertMessageType}
              dismissible
              onClose={() => this.setState({ showInfoAlert: false })}
            >
              {this.state.infoAlertMessage}
            </Alert>
          )}

          <Container fluid={true} style={{ paddingBottom: '100px' }}>
            {/* {this.state.isBatchSettingsOpen === false ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ color: "#000" }}
                  variant="link"
                  onClick={() => this.setState({ isBatchSettingsOpen: true })}
                >
                  <small>
                    Current Listing Profile: <strong>{this.state.currentListingProfile}</strong>. Click to show Listing
                    Profile Settings
                  </small>
                </Button>
              </div>
            ) : (
              <BatchSettings
                updateListingProfileName={this.updateListingProfileName}
                hideBatchSettings={this.hideBatchSettings}
                user_data={this.state.user_data}
              />

            )} */}

            <div id="containerIG">
              <InputGroup id="ig_asin">
                <InputGroup.Prepend className="ig_cat_dd">
                  <Dropdown
                    options={['All', 'Books', 'Music', 'Videos', 'Video Games']}
                    onChange={(cat) => {
                      this.setState({ currentCat: cat.value });
                    }}
                    value={this.state.currentCat}
                    placeholder="Select an option"
                  />
                  <div className="keyboard-selection" style={{ width: '40px' }}></div>
                  <Dropdown
                    className="keyboard-selection"
                    options={['AlphaNumeric', 'Numeric']}
                    onChange={(keyboardVal) => {
                      this.setState({ currentKeyBoard: keyboardVal.value }, () => {
                        console.log(`Chanding current keyboard: ${this.state.currentKeyBoard}`);
                      });
                    }}
                    value={this.state.currentKeyBoard}
                  />
                </InputGroup.Prepend>

                <FormControl
                  ref={(input) => {
                    this.asinInput = input;
                  }}
                  value={this.state.textInAsinBox}
                  onChange={(e) => {
                    this.setState({ textInAsinBox: e.target.value });
                  }}
                  autoComplete="off"
                  type={this.state.currentKeyBoard.toLowerCase().includes('alpha') ? 'text' : 'number'}
                  onKeyPress={(target) => {
                    if (target.charCode === 13) {
                      this.initiateSearch();
                    }
                  }}
                  id="asinInputBox"
                  placeholder="Search by ASIN, UPC or Title"
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Append className={'btn_asin_search'}>
                  <Button
                    onClick={() => this.initiateSearch()}
                    className={
                      'button-search ' +
                      (this.state.textInAsinBox === '' &&
                      this.state.currentASIN !== '' &&
                      this.state.fetchingAsinData === false
                        ? 'showAsinInButton'
                        : '')
                    }
                    disabled={this.state.textInAsinBox === '' || this.state.fetchingAsinData === true}
                  >
                    {this.state.fetchingAsinData === true ? (
                      <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <span>Search</span>
                    )}
                  </Button>
                </InputGroup.Append>
              </InputGroup>

              {this.state.isRestricted === 1 && (
                <div
                  style={{
                    background: '#E1422E',
                    padding: '7px',
                    width: '100%',
                    marginTop: '5px',
                    color: '#FFFFFF',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  AMAZON RESTRICTED
                </div>
              )}

              {this.state.asinData.meta &&
                this.state.asinData.meta.restriction &&
                this.state.asinData.meta.restriction.reasons && (
                  <Alert
                    variant="danger"
                    dismissible
                    onClose={() => this.setState({ showInfoAlert: false })}
                    style={{ textAlign: 'center', marginTop: '30px' }}
                  >
                    {this.state.asinData.meta.restriction.reasons[0].reasonCode +
                      ' : ' +
                      this.state.asinData.meta.restriction.reasons[0].message}
                  </Alert>
                )}

              {this.state.user_data.is_mm === 1 &&
                this.state.sourceList.length > 0 &&
                documentTitle !== 'EMPTY SHELVES' && (
                  <div style={{ marginTop: '10px' }}>
                    {this.state.user_data.hidden_mode === 0 && (
                      <div style={{ textAlign: 'center' }}>
                        Scanning for: {this.state.user_data.mp_source.name} | Pallet Value: $
                        {this.state.user_data.mp_source.pallet_value}{' '}
                        {'cart_count' in this.state.user_data.mp_source
                          ? `(${this.state.user_data.mp_source.cart_count} units)`
                          : ''}{' '}
                        | Remaining: $
                        {this.state.user_data.mp_source.pallet_value - this.state.totalMPScanned > 0
                          ? (this.state.user_data.mp_source.pallet_value - this.state.totalMPScanned).toFixed(2)
                          : 0}
                      </div>
                    )}

                    <div style={{ marginTop: '8px', display: 'flex' }}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({ isMPSourceInput: !this.state.isMPSourceInput })}
                      >
                        <i className="fa fa-info-circle " />
                      </div>
                      <div style={{ padding: '4px 0 5px 5px', flexGrow: 1 }}>
                        <ProgressBar
                          now={progressBarPercentage}
                          variant={
                            progressBarPercentage < 50
                              ? 'primary'
                              : progressBarPercentage >= 50 && progressBarPercentage < 100
                              ? 'warning'
                              : 'danger'
                          }
                          // label={`$${this.state.totalMPScanned} out of $${this.state.user_data.mp_source.pallet_value} scanned`}
                        />
                      </div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    {this.state.isMPSourceInput && this.state.sourceList.length > 0 && (
                      <Dropdown
                        // options={this.state.sourceList.map((sl) => {
                        //   return { value: sl.name, label: sl.name + " - $" + sl.pallet_value };
                        // })}
                        options={this.state.sourceList.map((sl) => {
                          return {
                            value: sl.name,
                            label:
                              sl.name +
                              (sl.pallet_notes !== '' && sl.pallet_notes !== null ? ' ' + sl.pallet_notes : ''),
                          };
                        })}
                        onChange={(source) => {
                          confirmAlert({
                            title: 'Update Marketplace Batch',
                            message: `Please select an option to update the current active marketplace batch`,
                            buttons: [
                              {
                                label: 'Just for me',
                                onClick: async () => {
                                  this.updateActiveSource(source.value, 'single');
                                },
                              },
                              {
                                label: 'For the whole team',
                                onClick: async () => {
                                  this.updateActiveSource(source.value, 'team');
                                },
                              },
                            ],
                          });
                        }}
                        value={this.state.user_data.mp_source.name}
                      />

                      // <div style={{ display: "flex", marginTop: "5px" }}>
                      //   <div style={{ padding: "8px 10px 0 0", minWidth: "200px" }}>
                      //     Current Source: <strong>{this.state.user_data.mp_source.name}</strong>
                      //   </div>
                      // </div>
                    )}
                  </div>
                )}

              {/* {this.state.isMPSourceInput && (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ padding: "8px 10px 0 0", minWidth: "200px" }}>
                    Current Source: <strong>ABCD</strong>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <FormControl
                      value={this.state.sourceName}
                      onChange={(e) => this.setState({ sourceName: e.target.value })}
                      placeholder="Source"
                      type={"text"}
                      className="mp-source"
                    />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <FormControl
                      value={this.state.sourcePalletValue}
                      onChange={(e) => this.setState({ sourcePalletValue: e.target.value })}
                      placeholder="Pallet Value"
                      type={"number"}
                      className="mp-source"
                    />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <Button className="mp-source" onClick={this.saveNewMPSource}>
                      Add
                    </Button>
                  </div>
                </div>
              )} */}
            </div>

            {this.state.showAsinSelectModal && (
              <Row>
                <Col md={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Preview</th>
                        <th>Title</th>
                        <th className="tableColMobHide">ASIN</th>
                        <th className="tableColMobHide">Category</th>
                        <th className="tableColMobHide">Rank</th>
                        {/* <th className="tableColMobHide">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.multipleItems !== undefined &&
                        this.state.multipleItems.map((singleItem) => {
                          return (
                            <tr onClick={() => this.selectAsin(singleItem.asin)} style={{ cursor: 'pointer' }}>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'center',
                                }}
                              >
                                <div>
                                  <Image src={singleItem.image_url} />
                                </div>
                                <div
                                  className="onlyOnMobile"
                                  style={{
                                    marginTop: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                    textTransform: 'uppercase',
                                    color: 'purple',
                                  }}
                                >
                                  {singleItem.product_group}
                                </div>
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <div className="smallFontOnMobile">{singleItem.title}</div>
                                <div className="onlyOnMobile">
                                  <div>
                                    <strong>ASIN:</strong> {singleItem.asin} | <strong>RANK:</strong>:{' '}
                                    {singleItem.sales_rank !== 100000000 ? (
                                      this.numberWithCommas(singleItem.sales_rank)
                                    ) : (
                                      <span style={{ color: 'red' }}>N/A</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td style={{ verticalAlign: 'middle' }} className="tableColMobHide">
                                {singleItem.asin}
                              </td>
                              <td style={{ verticalAlign: 'middle' }} className="tableColMobHide">
                                {singleItem.product_group}
                              </td>
                              <td style={{ verticalAlign: 'middle' }} className="tableColMobHide">
                                {singleItem.sales_rank !== 100000000 ? (
                                  this.numberWithCommas(singleItem.sales_rank)
                                ) : (
                                  <span style={{ color: 'red' }}>N/A</span>
                                )}
                              </td>
                              {/* <td style={{ verticalAlign: "middle" }} className="tableColMobHide">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    alert(singleItem.asin);
                                    this.selectAsin(singleItem.asin);
                                  }}
                                >
                                  Choose
                                </Button>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {'formatted' in this.state.asinData &&
              this.state.user_data.show_sales_rank === 1 &&
              (!this.state.isWLSite || this.state.wlFlags.sales_rank) && (
                <Row>
                  <Col md={12}>
                    <div className="item-meta">
                      eScore:{' '}
                      <span style={{ fontWeight: 'bold', color: 'beige' }}>{this.state.asinData.formatted.escore}</span>{' '}
                      <span style={{ marginLeft: '20px' }}>Rank: </span>
                      <span style={{ fontWeight: 'bold', color: 'beige' }}>
                        {this.state.asinData.formatted.salesrank}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}

            {'status' in this.state.asinData && this.state.asinData.status === 'success' && (
              <AsinTitle
                updatingWinner={this.state.updatingWinner}
                // isRestricted={this.state.isRestricted}
                printISBN={this.printISBNLabel}
                asinMeta={this.state.asinData.meta}
                title={this.state.asinData.meta.title}
                teamId={'team_id' in this.state.user_data ? this.state.user_data.team_id : -1}
                categories={this.state.asinData.meta.itemCategories}
                isValidBuy={this.state.isValidBuy}
                invalidAsin={this.state.invalidAsin}
                //ValidBuySource={this.state.ValidBuySource}
                moduleWinnerColor={this.state.moduleWinnerColor}
                moduleWinnerText={this.state.moduleWinnerText}
                moduleWinner={this.state.moduleWinner}
                subModuleWinner={this.state.subModuleWinner}
                winnerDisplay={
                  this.state.winnerMap !== null
                    ? this.state.winnerMap.display
                    : { name: 'N/A', bgColor: 'red', textColor: 'white' }
                }
              />
            )}
            {this.state.invalidAsin && <div className="titleAndDecisionInvalid">No Results Found</div>}
            {this.state.user_data.hidden_mode === 1 &&
              this.state.user_data.show_sales_rank === 1 &&
              (!this.state.isWLSite || this.state.wlFlags.sales_rank) &&
              this.state.invalidAsin === false &&
              this.state.fetchingAsinData === false &&
              'formatted' in this.state.asinData && (
                <div className="show_sales_rank">Sales Rank: {this.state.asinData.formatted.salesrank}</div>
              )}
            {((documentTitle === 'EMPTY SHELVES' && this.state.user_data.is_mm === 1) ||
              (this.state.isWLSite && this.state.wlFlags.infoboxes) ||
              ('infoboxes_enabled' in this.state.user_data &&
                (this.state.infoBoxesData.length > 0 ||
                  ('prices' in this.state.asinData &&
                    'bs' in this.state.asinData.prices &&
                    this.state.asinData.prices.bs.length > 0)) &&
                this.state.invalidAsin === false &&
                this.state.user_data.infoboxes_enabled === 1)) && (
              <InfoBoxes
                logBSClick={this.logBSClick}
                isRestricted={this.state.isRestricted}
                asinMeta={this.state.asinData.meta}
                bsPrices={
                  undefined !== this.state.asinData.prices && 'bs' in this.state.asinData.prices
                    ? this.state.asinData.prices.bs
                    : []
                }
                handleBSDoubleClick={this.handleBSDoubleClick}
                updateStaticWinner={this.updateStaticWinner}
                user_data={this.state.user_data}
                asin={this.state.currentASIN}
                infoBoxesData={this.state.infoBoxesData}
              />
            )}
            {'keepa' in this.state.asinData && (
              <Row>
                <Col md={12}>
                  <KeepaData data={this.state.asinData.keepa} offers={this.state.asinData.offers} />
                </Col>
              </Row>
            )}
            {'status' in this.state.asinData &&
              this.state.user_data.hidden_mode !== 1 &&
              (!this.state.isWLSite || !this.state.wlFlags.hidden_mode) &&
              this.state.asinData.status === 'success' && (
                <ItemInfo asinData={this.state.asinData} infoBoxesData={this.state.infoBoxesData} />
              )}

            {this.state.user_data.is_multi_profiles === 1 && this.state.user_data.profiles.length > 0 && (
              <div className="bottomProfileSwitch">
                {this.state.currentProfile !== '' ? (
                  <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>[[{this.state.currentProfile}]] is scanning right now</div>
                    </div>
                  </div>
                ) : (
                  <div>No Current Active Profile Exists. Scans will be recorded anonymously.</div>
                )}
              </div>
            )}
          </Container>
        </div>
      ) : (
        <div></div>
      );
    return componentToReturn;
  }
}

export default Home;
